/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Controls</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Bluetooth</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Cellular</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Hotspot</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Tethering</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Lighting</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">NFC</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">IR</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="vx-row mb-4">
                <div class="vx-col w-6/12 flex items-center">
                  <h2 class="font-bold">Question</h2>
                </div>
                <div class="vx-col w-6/12 flex items-center">
                  <h2 class="font-bold">Answer</h2>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4">
                <div class="vx-col w-6/12 flex items-center">
                  <!--- <img draggable="false" src="" width="50" class="mr-4"> --->
                  <h4 class="font-semibold">Devices use haptic confirmation for sharing data wirelessly.</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input0">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-4">
                <div class="vx-col w-6/12 flex items-center">
                  <!--- <img draggable="false" src="" width="50" class="mr-4"> --->
                  <h4 class="font-semibold">Devices are paired using a code for sharing data wirelessly.</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input1">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-4">
                <div class="vx-col w-6/12 flex items-center">
                  <!--- <img draggable="false" src="" width="50" class="mr-4"> --->
                  <h4 class="font-semibold">Devices are placed in close proximity within line of sight for sharing data wirelessly.</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input2">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-4">
                <div class="vx-col w-6/12 flex items-center">
                  <!--- <img draggable="false" src="" width="50" class="mr-4"> --->
                  <h4 class="font-semibold">Devices are connected via a wire for sharing data or connectivity.</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input3">
                  </vue-draggable-container>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,
      //last_drag_error: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'NFC') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'Bluetooth') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'IR') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'Tethering') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
